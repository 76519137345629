// i18next-extract-mark-ns-start article

import React from 'react'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import { DynamicallyStyledPageTitle } from 'src/components/typography/PageTitle'
import Subtitle from 'src/components/typography/Subtitle'
import Article from 'src/components/typography/Article'
import Section from 'src/components/typography/Section'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import { Link } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { PlainSubtitle } from 'src/components/typography/HighlightedSubtitle'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import tw from 'twin.macro'
import styled from 'styled-components'
import ParallaxImage from 'src/components/parallax-image'
import logos1886 from 'src/images/SVG/logos1886.svg'
import fifaLogo from 'src/images/SVG/fifa-logo.svg'
import federationsDesktop from 'src/images/SVG/background/federations-desktop.svg'
import federationsMobile from 'src/images/SVG/background/federations-mobile.svg'
import SemiRoundedButton, {
  ButtonSizes,
} from 'src/components/ui/SemiRoundedButton'
import AnimatedSvg from 'src/images/SVG/background/principles.inline.svg'
import AnimatedSvgDe from 'src/images/SVG/background/principlesDe.inline.svg'
import AnimatedSvgEs from 'src/images/SVG/background/principlesEs.inline.svg'
import AnimatedSvgFr from 'src/images/SVG/background/principlesFr.inline.svg'
import { useBackgroundAnimations } from 'src/components/layout/animations/background-animations'

const StyledHtml = styled.div`
  body & * {
    ${tw`text-white`}
  }
`

const StyledSubtitle = styled(Subtitle)`
  ${tw`text-almostBlackBlue border-almostBlackBlue`}
`

const IndexPage = ({ data: { model }, pageContext: { language } }) => {
  useBackgroundAnimations()

  const animatedSvgClasses = 'w-full md:w-5/12 block mx-auto'
  const animatedSvgAlt = 'IFAB - democratic, transparent, modern'

  const getAnimatedSvg = () => {
    switch (language) {
      case 'en':
        return (
          <AnimatedSvg className={animatedSvgClasses} alt={animatedSvgAlt} />
        )
      case 'de':
        return (
          <AnimatedSvgDe className={animatedSvgClasses} alt={animatedSvgAlt} />
        )
      case 'es':
        return (
          <AnimatedSvgEs className={animatedSvgClasses} alt={animatedSvgAlt} />
        )
      case 'fr':
        return (
          <AnimatedSvgFr className={animatedSvgClasses} alt={animatedSvgAlt} />
        )

      default:
        return <></>
    }
  }

  return (
    <>
      <Helmet>
        <title>The IFAB Background</title>
      </Helmet>
      <Layout>
        <div className="bg-grayBlue">
          <ContentPageContainer>
            <Section isNoMargin>
              <DynamicallyStyledPageTitle
                isSticked={false}
                className="whitespace-pre"
                style={{ minHeight: '100px' }}
              >
                <span className="text-almostBlackBlue whitespace-pre">
                  {model.title}
                </span>
              </DynamicallyStyledPageTitle>
              <StyledSubtitle>{model.subtitle}</StyledSubtitle>
              <Article className="mb-0" noMarginDesktop noMarginMobile>
                <div className="mt-12 mb-12">
                  <PlainSubtitle className="text-brightYellow">
                    <span className="text-brightYellow whitespace-pre-line hidden md:inline">
                      {model.highlightedText}
                    </span>
                    <span className="text-brightYellow whitespace-pre-line inline md:hidden">
                      {model.highlightedTextMobile}
                    </span>
                  </PlainSubtitle>
                </div>
                <StyledHtml>
                  <CmsHtmlContent
                    className="text-white"
                    dangerouslySetInnerHTML={{ __html: model.section }}
                  />
                </StyledHtml>
                <div className="mt-16 overflow-auto">{getAnimatedSvg()}</div>
              </Article>
            </Section>
          </ContentPageContainer>
        </div>
        <ParallaxImage
          isGrayBlueBackground
          desktopImage={model.transitionPicture[0].desktopImage}
          mobileImage={model.transitionPicture[0].mobileImage}
          id={model.transitionPicture[0].id}
        />
        <div className="bg-grayBlue overflow-hidden">
          <ContentPageContainer>
            <Section isNoMargin>
              <StyledSubtitle>{model.subtitle2}</StyledSubtitle>
              <div className="">
                <div className="flex mt-8 md:mt-16 mb-6 flex-wrap md:flex-nowrap">
                  <div className="text-brightYellow mr-8 self-start text-4xl md:text-6xl font-extralight w-32 flex-shrink-0">
                    1863
                  </div>
                  <div className="text-white border-deepDarkBlue mt-4 md:mt-0 md:mb-0 md:pl-8 md:border-l-2 w-full md:w-auto">
                    <StyledHtml>
                      <CmsHtmlContent
                        className="text-white"
                        dangerouslySetInnerHTML={{ __html: model.timeline1863 }}
                      />
                    </StyledHtml>
                  </div>
                  <div className="mt-10 md:mt-16 w-24 border-deepDarkBlue border-b-2 md:hidden" />
                </div>
                <div className="flex mt-8 md:mt-16 mb-6 flex-wrap md:flex-nowrap">
                  <div className="text-brightYellow mr-8 self-start text-4xl md:text-6xl font-extralight w-32 flex-shrink-0">
                    1886
                  </div>
                  <div className="text-white border-deepDarkBlue mt-4 md:mt-0 md:mb-0 md:pl-8 md:border-l-2 w-full md:w-auto">
                    <StyledHtml>
                      <CmsHtmlContent
                        className="text-white"
                        dangerouslySetInnerHTML={{ __html: model.timeline1886 }}
                      />
                      <img
                        src={logos1886}
                        alt="Logo of The FA, FA Wales, Scottish FA and Irish football association"
                        className="w-96 max-w-full mt-6"
                      />
                    </StyledHtml>
                  </div>
                  <div className="mt-10 md:mt-16 w-24 border-deepDarkBlue border-b-2 md:hidden" />
                </div>
                <div className="flex mt-8 md:mt-16 mb-6 flex-wrap md:flex-nowrap">
                  <div className="text-brightYellow mr-8 self-start text-4xl md:text-6xl font-extralight w-32 flex-shrink-0">
                    1913
                  </div>
                  <div className="text-white border-deepDarkBlue mt-4 md:mt-0 md:mb-0 md:pl-8 md:border-l-2 w-full md:w-auto">
                    <StyledHtml>
                      <CmsHtmlContent
                        className="text-white"
                        dangerouslySetInnerHTML={{ __html: model.timeline1913 }}
                      />
                    </StyledHtml>
                    <img
                      src={fifaLogo}
                      alt="Logo of The FA, FA Wales, Scottish FA and Irish football association"
                      className="w-28 max-w-full mt-6"
                    />
                  </div>
                  <div className="mt-10 md:mt-16 w-24 border-deepDarkBlue border-b-2 md:hidden" />
                </div>
                <div className="flex mt-8 md:mt-16 mb-6 flex-wrap md:flex-nowrap">
                  <div className="text-brightYellow mr-8 self-start text-4xl md:text-6xl font-extralight w-32 flex-shrink-0">
                    2014
                  </div>
                  <div className="text-white border-deepDarkBlue mt-4 md:mt-0 md:mb-0 md:pl-8 md:border-l-2 w-full md:w-auto">
                    <StyledHtml>
                      <CmsHtmlContent
                        className="text-white"
                        dangerouslySetInnerHTML={{ __html: model.timeline2014 }}
                      />
                    </StyledHtml>
                  </div>
                  <div className="mt-10 md:mt-16 w-24 border-deepDarkBlue border-b-2 md:hidden" />
                </div>
                <div className="flex mt-8 md:mt-16 mb-6 flex-wrap md:flex-nowrap">
                  <div className="text-brightYellow mr-8 self-start text-4xl md:text-6xl font-extralight w-32 flex-shrink-0">
                    2016
                  </div>
                  <div className="text-white border-deepDarkBlue mt-4 md:mt-0 md:mb-0 md:pl-8 md:border-l-2 w-full md:w-auto">
                    <StyledHtml>
                      <CmsHtmlContent
                        className="text-white"
                        dangerouslySetInnerHTML={{ __html: model.timeline2016 }}
                      />
                    </StyledHtml>
                  </div>
                  <div className="mt-10 md:mt-16 w-24 border-deepDarkBlue border-b-2 md:hidden" />
                </div>
                <div className="flex justify-center mt-24">
                  <img
                    src={federationsMobile}
                    alt="The International football association. Logotypes of The IFAB, The FA, FA Wales, FIFA, Scottish FA and Irish football association"
                    className="block md:hidden max-w-xs"
                  />
                  <img
                    src={federationsDesktop}
                    alt="The International football association. Logotypes of The IFAB, The FA, FA Wales, FIFA, Scottish FA and Irish football association"
                    className="hidden md:block"
                  />
                </div>
                <div className="flex justify-center mt-16">
                  <Link to={model.buttonLink}>
                    <SemiRoundedButton className="" size={ButtonSizes.medium}>
                      {model.buttonText}
                    </SemiRoundedButton>
                  </Link>
                </div>
              </div>
            </Section>
          </ContentPageContainer>
        </div>
        <div className="hidden md:block">
          <ParallaxImage
            isGrayBlueBackground
            desktopImage={model.transitionPicture2[0].desktopImage}
            mobileImage={model.transitionPicture2[0].mobileImage}
            id={model.transitionPicture2[0].id}
          />
        </div>
        <div className="bg-grayBlue overflow-hidden">
          <div className="block md:hidden mb-24" />
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    model: datoCmsBackgroundPage(locale: { eq: $language }) {
      title
      subtitle
      highlightedText
      highlightedTextMobile
      section
      image {
        url
        alt
      }
      transitionPicture {
        ... on DatoCmsTransitionImage {
          id
          model {
            apiKey
          }
          mobileImage {
            url
            alt
          }
          desktopImage {
            url
            alt
          }
        }
      }
      subtitle2
      timeline1863
      timeline1886
      timeline1913
      timeline2014
      timeline2016
      buttonLink
      buttonText
      transitionPicture2 {
        ... on DatoCmsTransitionImage {
          id
          model {
            apiKey
          }
          mobileImage {
            url
            alt
          }
          desktopImage {
            url
            alt
          }
        }
      }
    }
  }
`

export default IndexPage
