import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'

gsap.registerPlugin(ScrollTrigger)

export const useBackgroundAnimations = () => {
  const timeline = useRef(gsap.timeline())
  const isLarge = useIsLargeScreen()

  const timeOffsetForLine = 0.75
  const timeOfLinesAnimation = 2

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    gsap.set('#line', {
      opacity: 1,
      scaleX: 0,
    })
    gsap.set('#line-2', {
      opacity: 1,
      scaleX: 0,
    })
    gsap.set('#line-3', {
      opacity: 1,
      scaleX: 0,
      x: '100%',
    })
    gsap.set('#line-4', {
      opacity: 1,
      scaleX: 0,
    })

    ScrollTrigger.create({
      trigger: '#logo_full',
      start: '150px bottom',
      priority: 100,
      onEnter: () => {
        timeline.current.to('#logo_full', {
          opacity: 1,
          duration: 0.75,
        })
      },
    })

    ScrollTrigger.create({
      trigger: '#dem',
      start: '150px bottom',
      refreshPriority: 60,
      onEnter: () => {
        timeline.current.to(
          '#dem',
          {
            opacity: 1,
            duration: 0.75,
          },
          0.75
        )
      },
    })

    ScrollTrigger.create({
      trigger: '#trans',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to(
          '#trans',
          {
            opacity: 1,
            duration: 0.75,
          },
          1.25
        )
      },
    })

    ScrollTrigger.create({
      trigger: '#mod',
      start: '150px bottom',
      refreshPriority: 40,
      onEnter: () => {
        timeline.current.to(
          '#mod',
          {
            opacity: 1,
            duration: 0.75,
          },
          1.75
        )
      },
    })

    ScrollTrigger.create({
      trigger: '#mod',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to(
          '#line',
          {
            scaleX: 1,
            duration: timeOfLinesAnimation,
            x: 0,
          },
          timeOffsetForLine
        )
      },
    })

    ScrollTrigger.create({
      trigger: '#mod',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to(
          '#line-2',
          {
            scaleX: 1,
            duration: timeOfLinesAnimation,
            x: 0,
          },
          timeOffsetForLine
        )
      },
    })

    ScrollTrigger.create({
      trigger: '#mod',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to(
          '#line-3',
          {
            scaleX: 1,
            duration: timeOfLinesAnimation,
            x: 0,
          },
          timeOffsetForLine
        )
      },
    })

    ScrollTrigger.create({
      trigger: '#mod',
      start: '150px bottom',
      onEnter: () => {
        timeline.current.to(
          '#line-4',
          {
            scaleX: 1,
            duration: timeOfLinesAnimation,
            x: 0,
          },
          timeOffsetForLine
        )
      },
    })
  })
}

export default {}
